import sharpener_logo from '../assets/Images/AboutUS/newlogo.png';
import sharpener_new_logo from '../assets/Images/sharpener_new_logo.png';
import user from '../assets/Images/user.png';
export const APP_NAME = 'Sharpener';
export const APP_NAME_short = 'Mentor';
export const logo = sharpener_logo;
export const sharpenerNewLogo = sharpener_new_logo;
export const userDummyImage = user;
export const WEB_TOKEN = 'JWTTOKEN';
export const drawerWidth = 250;
export const navBarHeight = 0;
export const USER_DETAILS = 'USER_DETAILS';
export const BASE_URL = '';
export const THEME_COLOR = '#6f00ff';
export const DATE_TIME_FORMAT = 'Do MMM YYYY, hh:mm A';
export const ERROR_MSG = 'Something went wrong. Please try again !!!';

export const USER_DETAILS_OBJECT = 'USER_DETAILS_OBJECT';
export const MULTIVALUE_DELIMETER = '^^';
export const OPERATOR_DELIMITER = ';';

export const DEVELOPMENT = 'development';
export const STAGING = 'staging';
export const USER_ROLE_ID = 2;

export const availableLanguages = [
  { id: 54, name: 'C++ (GCC 9.2.0)', editorLanguage: 'c_cpp' },
  { id: 62, name: 'Java (OpenJDK 13.0.1)', editorLanguage: 'java' },
  {
    id: 63,
    name: 'JavaScript (Node.js 12.14.0)',
    editorLanguage: 'javascript',
  },
  { id: 71, name: 'Python (3.8.1)', editorLanguage: 'python' },
  { id: 50, name: 'C (GCC 9.2.0)', editorLanguage: 'c_cpp' },
  // { "id": 82, "name": "SQL (SQLite 3.27.2)", editorLanguage: "mysql" },
  // { "id": 74, "name": "TypeScript (3.7.4)", editorLanguage: "typescript" },
  // { "id": 51, "name": "C# (Mono 6.6.0.161)" },
  // { "id": 68, "name": "PHP (7.4.1)" },
  // { "id": 82, "name": "SQL (SQLite 3.27.2)" },
  // { "id": 74, "name": "TypeScript (3.7.4)" },
];

export const isaTypes = {
  ONLY_YAV_OFFERED: 'Only Jobs Referred By YAV',
  COMPANY_ISA: 'company ISA',
  DEFAULT_ISA: 'Default ISA',
  HR: 'HR',
};
export const userRole = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  MENTOR: 'MENTOR',
  HR: 'HR',
};
export const batchStatus = {
  ARCHIVE: 'Archive',
  ACTIVE: 'Active',
};
export const questionStatus = {
  ARCHIVE: 'Archive',
  ANSWERED: 'Answered',
  UNANSWERED: 'Un-Answered',
};
export const courseStatus = {
  ARCHIVE: 'Archived',
  LIVE: 'Live',
};
export const assignmentStatus = {
  ARCHIVE: 'Archived',
  LIVE: 'Live',
};
export const taskStatus = {
  ARCHIVE: 'Archived',
  LIVE: 'Live',
};
export const trackStatus = {
  ARCHIVE: 'Archived',
  LIVE: 'Live',
};
export const colors = {
  light: {
    text: '#e7e8d1',
    darkText: '#000000',
    lightText: '#1d1d1d',

    background: '#e4eded',
    lightBackground: '#E2FFFC',
    lighterBackground: '#e5e5e5',

    primaryColor: 'black',
    primaryColorLight: '#82b6ed',
    secondaryColor: '#ff8308',
  },
  dark: {
    text: '#E4E6EB',
    secondaryText: '#B0B3B8',
    darkText: '#5d615e',
    lightText: '#a5a6a8',

    background: '#18191A',
    lightBackground: '#242526',
    lighterBackground: '#3A3B3C',

    primaryColor: '#2EB67D',
    primaryColorLight: '#82b6ed',
    secondaryColor: '#E01E5A',
  },
};

export const isaStatus = {
  NOT_ASSIGNED: 'Not Assigned',
  NOT_SUBMITTED: 'Documents Not Submitted',
  DOCUMENT_SUBMISSION_OVERDUE: 'Document Submission Overdue',
  ISA_SUBMITTED: 'ISA Submitted',
  ACCEPTANCE_VIDEO_UPLOADED: 'ISA Acceptance Video Uploaded',
  ISA_VERIFIED: 'ISA Verified',
  ISA_TERMINATED: 'ISA Terminated',
};

export const productStatus = {
  ARCHIVE: 'Archive',
  ACTIVE: 'Active',
};

export const storeOrderStatus = {
  PENDING: 'Pending',
  FULFILLED: 'Fulfilled',
  REJECTED: 'Rejected',
};

export const courseClassStatus = {
  ARCHIVE: 'Archived',
  LIVE: 'Live',
};

export const enquiriesStatus = {
  PENDING: 'Pending',
  COMPLETED: 'Completed',
};

export const isaStatusColor = {
  [isaStatus.NOT_ASSIGNED]: '',
  [isaStatus.NOT_SUBMITTED]: 'red',
  [isaStatus.DOCUMENT_SUBMISSION_OVERDUE]: 'red',
  [isaStatus.ISA_SUBMITTED]: 'orange',
  [isaStatus.ACCEPTANCE_VIDEO_UPLOADED]: 'orange',
  [isaStatus.ISA_VERIFIED]: 'green',
  [isaStatus.ISA_TERMINATED]: 'red',
};

export const designations = [
  'AWS Engineer',
  'Analyst',
  'Android Developer',
  'Application Developer',
  'Application Engineer',
  'Associate Product Manager',
  'Associate Software Engineer',
  'Backend Developer',
  'Backend Engineer',
  'Cloud Engineer',
  'Cloud Infrastructure Engineer',
  'Database Engineer',
  'Developer',
  'Frontend Developer',
  'Frontend Engineer',
  'Graduate Software Engineer',
  'Graduate Trainee',
  'Intern',
  'Junior Software Engineer',
  'Member Technical Staff',
  'Mobile Application developer',
  'Principal Software Engineer',
  'Product Developer',
  'Product Manager',
  'Program Manager',
  'Programming Analyst',
  'Python Developer',
  'Quality Analyst',
  'Quality Assurance Engineer',
  'React Developer',
  'React Native Developer',
  'SDE I',
  'SDE II',
  'SDE III',
  'SDE IV',
  'SDE V',
  'SQL Developer',
  'Senior Analyst',
  'Senior Cloud Engineer',
  'Senior Cloud Infrastructure Engineer',
  'Senior Database Engineer',
  'Senior Developer',
  'Senior Member Technical Staff',
  'Senior Software Development Engineer',
  'Senior Software Engineer',
  'Senior Software Engineering Manager',
  'Software Developer',
  'Software Development Engineer',
  'Software Engineer',
  'Software Engineering Manager',
  'Software Tester',
  'Staff Software Engineer',
  'Team Lead',
  'Tech Lead',
  'Vice President',
  'Web Application Developer',
  'Web Developer',
];

export const boilerPlateCodeDelimeter = '!&!';

export const taskQuestionTypes = {
  1: 'MCQ',
  2: 'Multi select MCQ',
  3: 'Commit Id',
  4: 'Subjective',
};

export const AssignmentType = {
  Default: 1,
  Test: 2,
  ProTest: 3,
  Interview: 4,
};

export const taskDificulties = {
  Easy: 'Easy',
  Medium: 'Medium',
  Hard: 'Hard',
};

export const followUpStatus = {
  FollowUp: 'Follow up',
  InProgress: 'In Progress',
  Done: 'Done',
  Rejected: 'Rejected',
};

export const taskTypes = {
  coding: 'Coding',
  mcq: 'MCQs',
  learnAndSolve: 'Learn and Solve',
  resources: 'Resources',
  interviewer: 'Interviewer',
  demo: 'Demo',
  reactCompiler: 'React compiler',
  nodeCompiler: 'Node compiler',
  htmlWebsite: 'HTML, CSS & JS website',
  appointmentWithMentor: 'Appointment With Mentor',
  communication: 'Communication',
  sql: 'SQL',
  cssCompiler: 'CSS Compiler',
};

export const InterviewerRating = {
  Poor: 1,
  Average: 2,
  Good: 3,
};

export const InterviewerRatingText = {
  1: 'Poor',
  2: 'Average',
  3: 'Good',
};

export const weekDays = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const AppointmentFeedbackStatus = {
  passed: 'Passed',
  failed: 'Failed',
  absent: 'Absent',
  reallot: 'Re allot',
  warning: 'Warning',
};

export const InterviewStatus = {
  Scheduled: 1,
  Started: 2,
  Completed: 3,
};

export const GOOGLE_AD_URL =
  'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
export const AMPLITUDE_PROXY_SERVER_ENDPOINT = 'api-v5.yavtechnology.online';
export const AMPLITUDE_ENDPOINT = 'api.amplitude.com';

export const scheduledClassStatus = {
  ON_TIME: { text: 'On Time', color: 'primary' },
  POSTPONED: { text: 'Postponed', color: 'warning' },
  CANCELLED: { text: 'Cancelled', color: 'error' },
};

export const subjectiveStrikeComments = {
  Cheating: 'Cheating',
  Discipline: 'Discipline',
};

export const reAllotReasons = {
  MENTOR_TIME_ISSUE: 'Mentor timing issue',
  STUDENT_ISSUE: 'Student timing issue',
  MINOR_IMPROVEMENT: 'Student needs minor improvement',
};

export const appointmentTypes = {
  all: 'All',
  ProjectDemo: 'Project Demo',
  MentorMock: 'Mentor Mock',
  MentorFinalMock: 'Mentor Final Mock',
  DevFinalMock: 'Dev Final Mock',
  SubstituteMock: 'Substitute Mock',
  AlumniMock: 'Alumni Mock',
  GoogleSheet: 'Google Sheet',
};

export const appointmentTypeColors = {
  [appointmentTypes.ProjectDemo]: 'primary',
  [appointmentTypes.MentorMock]: 'secondary',
  [appointmentTypes.MentorFinalMock]: 'warning',
  [appointmentTypes.DevFinalMock]: 'info',
  [appointmentTypes.SubstituteMock]: 'success',
  [appointmentTypes.AlumniMock]: 'success',
};

export const allFeedbackFields = {
  TechnicalKnowledge: 'Technical Knowledge',
  Explanation: 'Explanation',
  Coding: 'Coding',
  Communication: 'Communication',
  Punctuality: 'Punctuality',
  OverallFeedback: 'Overall Feedback',
  ProjectImplementation: 'Project Implementation',
  Presentation: 'Presentation',
  OverallRating: 'Overall Rating',
};

export const appointmentTypeFeedbackFields = {
  [appointmentTypes.AlumniMock]: {
    TechnicalKnowledge: 'Technical Knowledge',
    Explanation: 'Explanation',
    Coding: 'Coding',
    Communication: 'Communication',
    Punctuality: 'Punctuality',
  },
  [appointmentTypes.DevFinalMock]: {
    TechnicalKnowledge: 'Technical Knowledge',
    Explanation: 'Explanation',
    Coding: 'Coding',
    Communication: 'Communication',
    Punctuality: 'Punctuality',
  },
  [appointmentTypes.SubstituteMock]: {
    TechnicalKnowledge: 'Technical Knowledge',
    Explanation: 'Explanation',
    Coding: 'Coding',
    Communication: 'Communication',
    Punctuality: 'Punctuality',
  },
  [appointmentTypes.MentorFinalMock]: {
    TechnicalKnowledge: 'Technical Knowledge',
    Explanation: 'Explanation',
    Coding: 'Coding',
    Communication: 'Communication',
    Punctuality: 'Punctuality',
  },
  [appointmentTypes.MentorMock]: {
    TechnicalKnowledge: 'Technical Knowledge',
    Explanation: 'Explanation',
    Coding: 'Coding',
    Communication: 'Communication',
    Punctuality: 'Punctuality',
  },
  [appointmentTypes.ProjectDemo]: {
    TechnicalKnowledge: 'Technical Knowledge',
    ProjectImplementation: 'Project Implementation',
    Communication: 'Communication',
    Presentation: 'Presentation',
    Punctuality: 'Punctuality',
  },
  [appointmentTypes.AlumniMock]: {
    TechnicalKnowledge: 'Technical Knowledge',
    ProjectImplementation: 'Project Implementation',
    Communication: 'Communication',
    Presentation: 'Presentation',
    Punctuality: 'Punctuality',
  },
  [appointmentTypes.GoogleSheet]: {
    TechnicalKnowledge: 'Technical Knowledge',
    Explanation: 'Explanation',
    Coding: 'Coding',
    Communication: 'Communication',
    Punctuality: 'Punctuality',
  },
};

export const appointmentRatings = {
  Good: {
    emoji: '🙂',
    text: 'Good',
    color: 'bg-green-500',
  },
  Bad: {
    emoji: '😢',
    text: 'Bad',
    color: 'bg-yellow-500',
  },
  Poor: {
    emoji: '😭',
    text: 'Poor',
    color: 'bg-red-500',
  },
};

export const MIN_OVERALL_FEEDBACK_LENGTH = 20;

export const teamTypes = {
  Main: 'Main',
  Substitute: 'Substitute',
  NotPresent: 'Not Present',
};

export const assignmentTaskTagsType = {
  TOPIC: 'Topic',
  COMPANY: 'Company',
};

export const SHARPENER_GRADIENT_COLOR =
  'linear-gradient(90deg, #f40b0bcc, #000c 87.19%)';

export const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const classScheduleStatus = {
  ON_TIME: 'On Time',
  POSTPONED: 'Postponed',
  CANCELLED: 'Cancelled',
};

export const dashboardFeedbackDropdownOptions = {
  DOUBT_SESSION: 'Doubt Session',
  APPOINTMENT: 'Appointment',
};

export const mentorsClassType = [
  { name: 'Live Class' },
  { name: 'Doubt Class' },
  { name: 'Live + Doubt' },
  { name: 'Theory Class' },
];

export const codingLanguageIds = {
  java: 62,
  cpp: 54,
  javascript: 63,
  python: 71,
  sql: 82,
  typescript: 74,
};

export const CONCEPT_CLASS = 'Concept Class';
export const loginMethods = {
  EMAIL: 'Email',
  MOBILE: 'Mobile Number',
};
