import moment from 'moment';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { dashboardFeedbackDropdownOptions } from '../../constants/otherConstants';

const MentorFeedback = ({ feedback, feedbackType }) => {
  return (
    <Table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden mt-8 font-poppins">
      <TableHeader className="bg-gray-100">
        <TableRow>
          <TableHead className="px-4 py-3 text-center text-sm font-semibold text-gray-600">
            Date
          </TableHead>
          <TableHead className="px-4 py-3 text-center text-sm font-semibold text-gray-600">
            Assignment Name
          </TableHead>
          <TableHead className="px-4 py-3 text-center text-sm font-semibold text-gray-600">
            Course Name
          </TableHead>
          <TableHead className="px-4 py-3 text-center text-sm font-semibold text-gray-600">
            Rating
          </TableHead>
          <TableHead className="px-4 py-3 text-start text-sm font-semibold text-gray-600">
            Feedback
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {feedback?.map((item, index) => (
          <TableRow key={index} className="hover:bg-gray-50">
            <TableCell className="px-4 py-3 font-medium text-gray-800">
              {moment(item?.createdAt).format('D MMMM YYYY')}
            </TableCell>
            <TableCell className="px-4 py-3 text-gray-700">
              {feedbackType === dashboardFeedbackDropdownOptions.APPOINTMENT
                ? item?.fields?.currentAssignmentName
                : item?.feedback?.assignmentName}
            </TableCell>
            <TableCell className="px-4 py-3 text-gray-700 ">
              {feedbackType === dashboardFeedbackDropdownOptions.APPOINTMENT
                ? item?.fields?.currentCourseName
                : item?.feedback?.courseName}
            </TableCell>
            <TableCell
              className={`px-4 py-3 text-gray-700 ${
                feedbackType === dashboardFeedbackDropdownOptions.APPOINTMENT
                  ? item?.fields?.rating <= 3
                  : item?.feedback?.rating <= 3
                  ? 'text-red-500'
                  : 'text-gray-700'
              }`}
            >
              {feedbackType === dashboardFeedbackDropdownOptions.APPOINTMENT
                ? item?.fields?.rating
                : item?.feedback?.rating}
            </TableCell>
            <TableCell className="px-4 py-3 text-gray-700 w-[40rem] text-start">
              {feedbackType === dashboardFeedbackDropdownOptions.APPOINTMENT
                ? item?.fields?.message || '-'
                : item?.feedback?.message || '-'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default MentorFeedback;
